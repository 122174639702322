.post_navigation {
    // @include flex($justifyContent: center);
    padding: 40px 0;
    background-color: $color-bg--neutral;
    margin-bottom: -50px;

    .navigation_link {
        @include flex($alignItems: center);
        font-family: $font-family;
        font-size: toRem(14);
        font-weight: 800;
        line-height: toRem(20);
        letter-spacing: 0.7px;
        color: $color-dark;
        text-transform: uppercase;
        transition: color $duration $timing;

        svg {
            @include size(26px);
            margin-right: 5px;
            fill: $color-dark;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            opacity: 0.8;
        }
    }

    // .return {
    //     .navigation_link svg {
    //         position: relative;
    //         top: 5px;
    //     }
    // }
}

// .navigation_link svg {
//     position: relative;
//     top: 5px;
// }

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
      // A adapter en fonction de la width du bouton précédent
      min-width: 121px;

        .navigation_link {
            color: $color-text;

            // Recup style ~ ligne 9
            a {
                @include flex($alignItems: center);
                font-family: $font-family;
                font-size: toRem(14);
                font-weight: 800;
                line-height: toRem(20);
                letter-spacing: 0.7px;
                color: $color-dark;
                text-transform: uppercase;
                transition: color $duration $timing;
            }
        }
    }

    .prev {
        .navigation_link {
          display: flex;
          justify-content: flex-start;

            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-text;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }
            }
        }
    }

    .next {
        .navigation_link {
             display: flex;
            justify-content: flex-end;

          a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-text;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {
            opacity: 0.8;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .post_navigation {
        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
        }
    }
}