@keyframes headerFixed {
    from {
        top: -200px;
    }
    to {
        top: 0;
    }
}

.header {
    background-color: $color-white;

    &__container {
        display: flex;
        justify-content: space-between;
        height: 100px;
    }

    &__link {
        position: relative;
        z-index: 2;
        display: flex;

        &:before {
            position: absolute;
            content: "";
            width: 390px;
            height: 250px;
            left: -90px;
            top: -45px;
            background-image: url(/wp-content/themes/gauchy/assets/src/images/sprite-images/home/bulle_logo.svg);
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__svg {
        &--classic {
            width: 200px;
            height: 90px;
            margin-top: 30px;
        }

        &--fixe {
            display: none;
        }
    }

    &__title {
        font-size: 0;
        line-height: 0;
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    &--fixed {
        box-shadow: $shadow;
        left: 0;
        padding: 0;
        position: fixed;
        right: 0;
        top: 0;
        transform: unset;
        width: unset;
        z-index: 15;
        animation: 1s headerFixed;

        .header {
            &__container {
                height: 80px;
            }

            &__link {
                display: flex;
                align-items: center;

                &:before {
                    width: 270px;
                    height: 160px;
                    left: -47px;
                    top: 0;
                    background-image: url(/wp-content/themes/gauchy/assets/src/images/sprite-images/home/bulle_logo_menu_fixe.svg);
                }
            }

            &__svg {
                &--classic {
                    display: none;
                }
        
                &--fixe {
                    display: flex;
                    width: 160px;
                    height: 40px;
                }
            }
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    display: flex;
    align-items: center;
    gap: 15px;

    &__item {
        height: 40px;
        border-radius: $border-radius;
        padding: 0;
        margin: 0;

        &--accessibility {
            width: 40px;
            background-color: $color-second;
            border-color: $color-second;

            svg {
                width: 100%;
                height: 100%;
                fill: $color-dark;
            }
        }

        &--search {
            width: 173px;
            background-color: $color-main;
            border-color: $color-main;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: 800;
            line-height: toRem(20);
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: $color-dark;

            svg {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                fill: $color-dark;
            }

            &--disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    #accessconfig {
        height: 40px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__link {
            &:before {
                width: 305px;
                height: 195px;
                background-size: 305px;
                top: -18px;
                left: -55px;
            }
        }

        &__svg--classic {
            width: 185px;
            height: 85px;
            margin-top: 33px;
        }

        &--fixed {
            .header {
                &__link:before {
                    height: 130px;
                    background-size: 305px;
                    width: 305px;
                    left: -70px;
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        &__link {
            &:before {
                width: 360px;
                height: 230px;
                background-size: 360px;
                left: -110px;
                top: -55px;
            }
        }
    }
}

// 715
@media screen and (max-width: 715px) {
    .tools__item--search {
        width: 40px;

        svg {
            margin: 0;
        }

        span {
            display: none;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .header {
        &__container {
            height: 80px;
        }

        &__link {
            &:before {
                width: 210px;
                height: 315px;
                background-size: 210px;
                left: -65px;
                top: -25px;
            }
        }

        &__svg--classic {
            width: 110px;
            height: 50px;
            margin-top: 13px;
        }

        &__content {
            gap: 10px;
        }
    }

    .tools {
        gap: 10px;
    }
}
