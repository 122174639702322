.card {

    &__container {
        // Events v1
        &--event-v1 {
            gap: $card-event-v1-gap;
            padding: $card--padding;

            .card__content {
                padding: $card-content--padding;
            }
        }

        &:hover, &:focus {
            .card__date {
                background-color: $color-main;
            }
        }
    }


    //===========================
    // Events
    //===========================
    &__date {
        display: flex;
        align-items: stretch;
        padding: 20px 0;
        background-color: $color-second;
        border-radius: 10px;
        margin-right: 15px;
        transition: all $duration ease-in-out;

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100px;
            color: $color-dark;
        }

        &__many {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &_day {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            font-size: $font-size--2;
            line-height: $line-height--heading;
        }

        &_month {
            font-size: $font-size--text-small;
            text-transform: uppercase;
            margin-top: 5px;
        }

        svg {
            box-sizing: content-box;
            width: 26px;
            height: 26px;
            fill: $color-dark;
        }
    }

    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.events,
        &.events_category {
            background-image: url(/wp-content/themes/noyau/assets/images/icon-events.png);
        }
    }


    //=================================================================================================
    // Squared cards appearance
    //=================================================================================================
    &--square {
        .card {

            //===========================
            // Events
            //===========================
            &__date {
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                padding: 0;
                background-color: $color-second;
                border-radius: 0 0 10px 10px;
                padding: 0 10px;
                margin-right: 0;

                p {
                    width: fit-content;
                    height: 100px;
                    color: $color-dark;
                    padding: 15px 0;
                }

                svg {
                    box-sizing: inherit;
                    padding: 0;
                    fill: $color-dark;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 960
@media screen and (max-width: $medium) {

    .card {
        &:not(.card--square):not(.card--category):not(.card--portraits) {

            .card__image-wrapper:not(.events) {
                display: none;
            }

            .card__image-wrapper.events {
                background: none;
                width: auto;

                img {
                    display: none;
                }
            }

            .card__date {
                position: relative;

                p {
                    width: 85px;
                }
            }

        }

        &__container--event-v1 {
            // height: 260px;

            .card__date {
                padding: 20px 5px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .card  {
        &:not(.card--square):not(.card--category):not(.card--portraits) {

            .card__image-wrapper.events {
                background: none;
                width: 100%;
                height: 70px;
                margin: 0 0 20px 0;
            }

            .card__date {
                position: relative;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 0;
                margin-bottom: 15px;
                margin-right: 0;

                &__many {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                p {
                    width: auto;
                    padding: 15px;
                }

                svg {
                    transform: rotate(-90deg);
                }
            }
        }

        &__container--event-v1 {
            height: auto;
        }
    }

}
