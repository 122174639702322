.cover {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 410px;
        height: 380px;
        right: -255px;
        top: 305px;
        border-radius: 438px;
        background: linear-gradient(221.59deg, rgba(255, 163, 43, 0.6) 27.12%, rgba(224, 110, 174, 0.6) 76.49%);
        filter: blur(100px);
        z-index: -1;
    }

    &__image-wrapper {
        width: 100%;
        height: 450px;
        background-color: $color-light;
        border-radius: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: 10px;
        }
    }

    &__title {
        font-family: $font-family--heading;
        font-size: toRem(50);
        font-weight: 700;
        line-height: toRem(55);
        margin: 0;
    }

    &__intro {
        font-family: $font-family;
        font-size: toRem(18);
        font-weight: 700;
        line-height: toRem(25);
        margin: 15px 0 80px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__image-wrapper {
            height: 445px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        &:before {
            top: 225px;
            right: -200px;
            width: 355px;
            height: 330px;
            background-size: 355px;
        }

        &__image-wrapper {
            height: 380px;
        }

        &__title {
            font-size: toRem(40);
            line-height: toRem(45);
        }
    
        &__intro {
            font-size: toRem(18);
            line-height: toRem(24);
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .cover {
        &:before {
            top: 180px;
        }

        &__image-wrapper {
            height: 255px;
        }

        &__title {
            font-size: toRem(30);
            line-height: toRem(35);
        }
    }
}
