.footer {
    background-color: $color-dark;
    color: $color-white;
    padding: 80px 0 50px;
    margin-top: 100px;

    &__infos {
        display: flex;
    }

    &__coordonnees {
        width: 250px;
        margin-right: 80px;

        .footer__text {
            margin-bottom: 15px;
        }
    }

    &__horaires {
        width: 320px;
        margin-right: 95px;
    }

    &__buttons {
        @include flex($direction: column);
        gap: 20px;
    }

    &__button {
        width: 285px;
        gap: 10px;
        margin: 0;

        svg {
            width: 20px;
            height: 20px;
            transition: all $duration ease-in-out;
        }

        &:first-child {
            background-color: $color-white;
            border-color: $color-white;
        }

        &:hover, &:focus {
            background-color: $color-second;
            border-color: $color-second;
            color: $color-dark;

            svg {
                transform: rotate(90deg);
                fill: $color-dark;
            }
        }
    }

    &__title {
        font-family: $font-family;
        font-size: toRem(22);
        font-weight: 700;
        line-height: toRem(25);
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 36px;
            height: 40px;
            left: -20px;
            top: -10px;
            background-image: url(/wp-content/themes/gauchy/assets/src/images/sprite-images/home/bulle_footer.svg);
            background-repeat: no-repeat;
            z-index: 0;
        }
    }

    &__text {
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);
        margin-top: 15px;
    }

    &__phone {
        font-weight: 700;
        transition: all $duration ease-in-out;

        &:hover, &:focus {
            color: $color-main;
        }
    }

    &__menu {
        padding: 50px 0 0;

        ul {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: flex-start);
            width: 100%;
            gap: 36px;
        }

        li {
            position: relative;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);

            a {
                &:hover, &:focus {
                    color: $color-main;
                }
            } 

            &::before {
                content: '';
                @include absolute($top: 50%, $right: -20px);
                @include size(1px, 10px);
                background-color: $color-white;
                transform: translateY(-50%);
            }

            &:last-child::before {
                background-color :transparent;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        margin-top: 80px;

        &__coordonnees {
            width: 215px;
            margin-right: 55px;
        }

        &__horaires {
            margin-right: 100px;
        }

        &__button {
            width: 250px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__infos {
            flex-wrap: wrap;
        }

        &__coordonnees {
            width: 50%;
            margin-right: 0;
        }

        &__horaires {
            width: calc(50% - 10px);
            margin-left: 10px;
            margin-right: 0;
        }

        &__buttons {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: unset;
            justify-content: space-between;
            margin-top: 45px;
        }

        &__button:nth-child(2) {
            margin-left: 10px;
        }

        &__menu {
            padding: 45px 0 0;

            ul {
                width: 485px;
                gap: 10px 61px;
            }

            li {
                &:before {
                    right: -31px;
                }

                &:nth-child(3) {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__coordonnees {
            width: 100%;
        }

        &__horaires {
            width: 100%;
            margin-left: 0;
            margin-top: 57px;
        }

        &__buttons {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &__button {
            width: 100%;

            &:nth-child(2) {
                margin-left: 0;
            }
        }

        &__menu {

            ul {
                width: 100%;
            }

            li {
                &:nth-child(1), &:nth-child(2), &:nth-child(4) {
                    &:before {
                        display: none;
                    }
                }

                &:nth-child(3) {
                    &:before {
                        display: flex;
                    }
                }
            }
        }
    }
}