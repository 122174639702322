.nav-main--burger {
    z-index: inherit;

    // Burger menu Button
    .nav-main-buttons {
        position: relative;
        padding: 0;

        &__menu {
            @include flex($justifyContent: center, $alignItems: center);
            padding: 0;
            margin: 0;
            height: 40px;
            position: relative;
            border: 0;
            border-radius: $border-radius;
            transition: all $duration $timing;
            cursor: pointer;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: 800;
            line-height: toRem(20);
            letter-spacing: 0.7px;
            text-transform: uppercase;

            &--open {
                width: 114px;
                z-index: 0;
                column-gap: 10px;
                background-color: $color-dark;
                color: $color-white;
    
                svg {
                    width: 20px;
                    height: 20px;
                    fill: $color-white;
                }

                &:hover, &:focus {
                    background-color: $color-second;
                    color: $color-dark;

                    svg {
                        fill: $color-dark;
                    }
                }
            }

            &--close {
                width: 125px;
                position: absolute;
                top: 30px;
                left: 40px;
                z-index: 2;
                gap: 10px;
                background-color: $color-white;

                svg {
                    height: 18px;
                    width: 18px;
                    fill: $color-dark;

                    path {
                        fill: $color-dark;
                    }
                }

                &:hover, &:focus {
                    background-color: $color-second;
                    color: $color-dark;
                }
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }

    }

    // Popup menu
    &__popup {
        @include fixed($top: -100vh, $left: 0, $right: 0);
        z-index: 10;
        display: block;
        max-width: inherit;
        @include size(100%, 100vh);
        background-color: rgba($color-dark, 0.7);
        padding: 0;
        backdrop-filter: blur(2.8px);
        transition: {
            property: top, left;
            duration: .5s, $duration;
            timing-function: $timing;
        }
    }

    // Section menu (for overflow)
    &__section {
        height: 100%;
        padding: 0;
        position: relative;
        z-index: -2;
    }

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: flex-start);
        max-width: 100%;
        padding: 0;
        position: relative;
        z-index: -2;
        height: 100%;
    }

    // if Burger menu open
    &.js-open-menu {

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
            transition: top .7s cubic-bezier(0.4, 0, 0.2, 1);
        }

        // Main menu
        .menu {
            position: relative;
            display: flex;
        }

    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow-y: auto;
        @include size(400px, 100%);
        margin-top: 0;
        margin-bottom: 0;
        padding: 103px 0 40px 0;
        border-radius: 0px 10px 10px 0px;
        background: linear-gradient(110deg, #6BD7D9 0%, #67D2B9 100%), linear-gradient(110deg, #6BD7D9 0%, #19A3AC 100%);

        &__item {
            @include flex($alignItems: center, $justifyContent: space-between);
            width: 100%;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            position: unset;
            transition: all $duration ease-in-out;

            &.menu-item-has-children {
                &.js-open-submenu {
                    background-color: $color-second;

                    .menu__link {
                        padding-left: 50px;
                        padding-right: 35px;
                    }
                    .submenu {
                        left: 390px;
                    }
                }
            }

            &:first-child {
                border-top: 1px solid hsla(0,0%,100%,.15);
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center, $justifyContent: space-between);
            max-width: inherit;
            @include size(100%, auto);
            padding: 16.5px 60px 16.5px 35px;
            color: $color-dark;
            text-transform: uppercase;
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: 800;
            line-height: toRem(25);
            letter-spacing: 0.8px;
        }
    }

    // Sub menu
    .submenu {
        position: fixed;
        width: fit-content;
        min-width: 450px;
        height: 100vh;
        left: -1000px;
        top: 0;
        z-index: -1;
        padding: 0 50px 0 60px;
        background-color: $color-white;
        border-radius: 0px 10px 10px 0px;
        box-shadow: none;
        transition: left 0.8s ease-in-out;

        &__nav {
            @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 5px 30px;
            max-width: inherit;
            padding: 0;
            margin-top: 100px;
        }

        &__title {
            font-family: $font-family;
            font-size: toRem(25);
            font-weight: 800;
            line-height: toRem(30);
            margin-bottom: 15px;
        }

        &__item {
            width: auto;
            margin: 0;
        }

        &__link {
            position: relative;
            padding: 10px 0 10px 22px;
            color: $color-dark;
            font-family: $font-family;
            font-size: toRem(18);
            font-weight: 600;
            line-height: toRem(25);
            display: flex;
            align-items: center;
            max-width: 500px;
            transition: all $duration ease-in-out;

            &:before {
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-image: url(/wp-content/themes/gauchy/assets/src/images/svg/submenu_fleche.svg);
                background-repeat: no-repeat;
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;
            }

            &:hover, &:focus {
                transform: translateX(10px);
            }
        }
    }

}

// Button accessibility
button.avoid-main-content {
    @include absolute($bottom: 40px, $right: 40px);
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For no scroll on body when menu is open
body.js-open-menu {
    overflow: hidden;
}


//=================================================================================================
// Header only for burger menu on demosaisie
//=================================================================================================
// Tools
.header .tools--burger {
    top: auto;
}

// Identity
.identity.identity--burger {
    position: relative;
    z-index: 11;
    width: fit-content;
    height: auto;
    max-width: 85%;
    min-height: 90px;

    .identity__title {
        transition: {
            property: margin-top, color;
            duration: $duration;
            timing-function: $timing;
        }
    }
}

body.js-open-menu {
    .identity__title {
        color: $color-white;
    }

    .quick-access {
        right: -960px;
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
// @media screen and (max-width: $large) {
// }


// 960
@media screen and (max-width: $medium) {
    
    // Header -- Identity
    .identity.identity--burger {
        position: inherit;
        z-index: inherit;
        max-width: 100%;
        height: auto;
    }
    body.js-open-menu .identity__title {
        margin-top: 0;
        color: $color-text;
    }

    // Menu
    .nav-main--burger {
        position: initial;
        height: auto;

        // if Burger menu open
        &.js-open-menu {

            // Burger menu Button
            .nav-main-buttons__menu { 
                &--close {
                    right: 30px;
                    left: unset;
                }
            }

            // Popup menu
            .nav-main--burger__popup {
                top: 0;
                // left: 0;
                transition: all $duration $timing;
            }

            // And If submenu open
            .nav-main-buttons.js-open-submenu {
                .nav-main-buttons__submenu {
                    left: 0;
                }
            }
        }

        // If submenu open
        &.js-open-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }

            .nav-main-buttons__menu--close {
                background-color: $color-dark;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            position: inherit;
            @include flex($alignItems: center, $justifyContent: flex-start);
            margin: 0;
            max-width: 100%;

            &__menu {
                &--open {
                    min-width: 114px;
                }
            }

            // Burger menu responsive - Close submenu
            &__submenu {
                @include absolute($top: 0, $left: -100vw);
                z-index: 11;
                @include flex($alignItems: center, $justifyContent: center);
                flex-shrink: 0;
                @include size(40px);
                margin: 30px auto 0 30px;
                padding: 0;
                background-color: $color-second;
                border: none;
                border-radius: 10px;
                transition: left $duration $timing;

                svg {
                    @include size(100%);
                    fill: $color-dark;
                }
            }
        }

        // Popup menu
        &__popup {
            // top: 0;
            left: 0;
            right: inherit;
            top: -100vh;
            // left: -100vh;
            padding: 0;
            background: linear-gradient(110deg,#6bd7d9,#67d2b9),linear-gradient(110deg,#6bd7d9,#19a3ac);

            &::before {
                height: 100px;
            }
        }

        // Section menu
        &__section {
            padding: 0;
            overflow-y: auto;
        }

        // Container menu
        &__container {
            max-width: initial;
            margin: 0;
            padding: 0;
        }

        // Main menu
        .menu {
            flex-wrap: nowrap;
            @include size(100%, auto);
            min-height: 100vh;
            margin: 0;
            padding: 100px 0 40px;
            gap: 0;
            overflow: hidden;
            background-color: none;

            &::after {
                display: none;
            }

            &__item {
                &.menu-item-has-children {

                    &:focus-within .submenu,
                    &:hover .submenu {
                        display: flex;
                    }

                    &.js-open-submenu {
                        .submenu {   
                            left: 0;
                            display: flex;
                        }
                    }

                    .menu__link::after {
                        display: none;
                    }
                }
            }

            &__link {
                padding: 12px 30px;

                &::before {
                    display: none;
                }
            }
        }

        // Sub menu
        .submenu {
            @include fixed($top: 0, $right: inherit, $left: -100vw);
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 100px 0 0;
            background-color: $color-white; 
            border-radius: 0;
            min-width: unset;
            transition: left $duration $timing;

            &::after {
                display: none;
            }
    
            &__nav {
                @include flex($direction: column, $justifyContent: flex-start, $wrap: nowrap);
                gap: initial;
                @include size(100%, calc(100vh - 100px));
                max-width: 100%;
                max-height: inherit;
                overflow-y: auto;
                padding: 5px 40px 40px;  
                margin-top: 0;
            }

            &__title {
                margin-bottom: 20px;
            }

            &__item {
                width: 100%; 
            }

            &__link {
                width: 100%;
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .menu, .submenu {
                padding-top: 132px;
            }

            &__popup::before {
                height: 132px;
            }
        }
    }
}


// 782 -- For admin bar only
@media screen and (max-width: 782px) {

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .menu, .submenu {
                padding-top: 146px;
            }
            
            &__popup::before {
                height: 146px;
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {
    .nav-main--burger {
        .nav-main-buttons__menu {
            &--open {
                font-size: 0;
                line-height: 0;
                width: 40px;
                min-width: unset;
                letter-spacing: 0;
                
                svg {
                    margin-left: 10px;
                }
            }
        }
        
        .menu {
            padding: 100px 0 
        }
    }
}