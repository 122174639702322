.breadcrumb {
    margin: 10px 0 15px;
    
    ul {
        display: block;
        min-height: 60px;
        padding: 18px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-dark;
        line-height: 1.75;

        a {
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: 400;
            line-height: toRem(25); 

            &:hover, &:focus {
                text-decoration: underline;
                color: $color-dark;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-dark;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
