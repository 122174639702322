@use "sass:list"; // https://sass-lang.com/documentation/breaking-changes/slash-div

//=================================================================================================
// HTML base
//=================================================================================================
html {
    font: $font-style $font-variant $font-weight list.slash(#{$font-size}, #{$line-height}) $font-family;
    font-size: $font-size;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // If need https://caniuse.com/?search=scroll-behavior%3A%20smooth
    // scroll-behavior: smooth;
}

//=================================================================================================
// Body base
//================================================================================================= 
body {
    font-size: $font-size--text;
    color: $color-text;
    background: $color-bg--body;
    overflow-x: hidden;

    &:not(.home) {
        h2 {
            position: relative;
            display: flex;
            align-items: center;
            gap: 15px;

            &:before {
                content: "";
                min-width: 18px;
                width: 18px;
                height: 20px;
                background-image: url(/wp-content/themes/gauchy/assets/src/images/sprite-images/home/bulle_titre.svg);
                background-repeat: no-repeat;
                display: inline-block;
            }
        }
        
        .footer {
            margin-top: 50px;
        }
    }

    &.error404, &.search {
        .cover__informations {
            margin-top: 80px;
        }
    }
}

ol,
ul {
    list-style-type: none;
}

p,
ol,
ul,
dl,
table {
    margin: 0 0 0 0;
    padding: 0;
}


//=================================================================================================
// Remove extra margin for nested lists
//================================================================================================= 
ul li ul {
    margin-bottom: 0;
}

ol li ol {
    margin-bottom: 0;
}


//=================================================================================================
// Headings
//================================================================================================= 
h1,
h2,
h3,
h4,
h5 {
    font-family: $font-family--heading;
    font-weight: $font-weight--heading;
    line-height: $line-height--heading;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin: 25px 0 10px;
}

.wysiwyg h2 {
    margin: 0px 0px 30px;
    padding: 5px 0;
}


//=================================================================================================
// Heading individual styles
//================================================================================================= 
h1 {
    font-size: $font-size--1;
}

h2,
.gform_wrapper form h2.gsection_title,
.title-newsletter-h2 {
    font-family: $font-family--heading;
    font-size: toRem(34);
    font-weight: 700;
    line-height: toRem(40);
    padding-bottom: 10px;
}

h3 {
    font-family: $font-family;
    font-size: toRem(25);
    font-weight: 800;
    line-height: toRem(30);
}

h4 {
    font-family: $font-family;
    font-size: toRem(22);
    font-weight: 700;
    line-height: toRem(25);
}

h5 {
    font-size: $font-size--5;
}

h6 {
    font-size: $font-size--6;
}


//=================================================================================================
// Pictures styling
//=================================================================================================
//img:before {
//}


//=================================================================================================
// Link styling
//================================================================================================= 
a {
    color: inherit;
    text-decoration: none;
    transition: color $duration $timing;

    &:hover,
    &:active,
    &:focus {
        color: $color-dark;
        text-decoration: none;
    }
}

.wysiwyg {
    a {
        color: $color-link;
    }
}


//=================================================================================================
// Highlight
//================================================================================================= 
mark {
    background: $color-highlight;
    padding: 0 0.2rem;
}


//=================================================================================================
// Blockquote
//================================================================================================= 
blockquote {
    @include font-size(24);
    padding: 0 1.5rem;
    border-left: $borders--blockquote;
    margin: 0;

    cite {
        display: block;
        font-size: $font-size--text;
        text-align: right;
        margin-top: 0;
    }
}


//=================================================================================================
// Code block styling
//=================================================================================================
pre {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    tab-size: 2;
    padding: 1rem;
    border: 0;
    border-radius: $border-radius;
    margin: 0;

    code {
        font-family: $code-family;
        line-height: 1.2;
    }
}


//=================================================================================================
// Keyboard input
//================================================================================================= 
kbd {
    display: inline-block;
    line-height: 1.4;
    font-family: "Helvetica", Arial, sans-serif;
    font-size: $font-size--text-xsmall;
    color: #333;
    text-shadow: 0 1px 0 #fff;
    background-color: #f7f7f7;
    padding: .1em .6em;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    margin: 0 .1em;
}


//=================================================================================================
// Inline code styling
//================================================================================================= 
:not(pre)>code {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    padding: 0 0.2rem;
    border: $borders;
    border-radius: $border-radius;
}


//=================================================================================================
// Line break
//================================================================================================= 
hr {
    height: 0;
    border: 0;
    border-top: $borders;
}


//=================================================================================================
// Definition list
//================================================================================================= 
dt {
    font-weight: $font-weight-semibold;
}

dd {
    margin-bottom: .5rem;
}


//=================================================================================================
// Image/video wrapper
//================================================================================================= 
img {
    max-width: 100%;
    height: auto;
}

.image-wrapper {
    min-height: 100%;
    position: relative;
    @include flex($justifyContent: center, $alignItems: center);
    background-color: $color-light;
    overflow: hidden; 
}

.image-wrapper {
    video {
        margin-bottom: 10px;
    }
}


//=================================================================================================
// Gutter - Placement 
//================================================================================================= 
.no-gutter {
    padding-right: 0;
    padding-left: 0;
}

.content-right {
    text-align: right;
}

.container-center {
    @include flex($justifyContent: center, $alignItems: center);
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    h1 {
        @include font-size(38);
    }

    h2 {
        @include font-size(28);
        line-height: toRem(35);
    }

    h3 {
        @include font-size(24);
    }

    h4 {
        @include font-size(19);
    }

}
