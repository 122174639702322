//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    z-index: 0;

    &:before {
        position: absolute;
        content: "";
        width: 410px;
        height: 380px;
        left: calc(50% + 470px);
        bottom: -235px;
        border-radius: 438px;
        background: linear-gradient(221.59deg, rgba(255, 163, 43, 0.6) 27.12%, rgba(224, 110, 174, 0.6) 76.49%);
        filter: blur(100px);
    }

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        
        &--pagination {
            @include absolute(0, 0, 0, 0);
            height: 100%;
        }
    }

    .slideshow {
        @include size(1170px, 550px);

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            @include size(100%);
            border-radius: $border-radius;
        }

        &--video {
            margin: auto;
        }

        &__container {
            display: inherit;
            position: relative;
            @include size(100%);
        }

        &__content {
            @include flex($direction: column);
            box-sizing: border-box;
            @include absolute($right: 20px, $bottom: 45px);
            @include size(fit-content, auto);
            max-width: 430px;
            padding: 20px;
            background-color: $color-white;
            color: $color-dark;
            border-radius: $border-radius;
        }

        &__title {
            margin-bottom: 10px;
            font-family: $font-family;
            font-size: toRem(25);
            font-weight: 800;
            line-height: toRem(30);
        }

        &__description {
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: 400;
            line-height: toRem(25);
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            @include absolute($bottom: 20px, $right: 25px);
            @include flex($alignItems: center, $justifyContent: center);
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                @include size(10px);
                margin: 0 10px 0 0;
                background-color: $color-white;
                border-radius: 5px;
                opacity: 1;
                transition: {
                    property: width, background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
    
                &:hover, &:focus {
                    background-color: $color-second;
                }
    
                &-active {
                    background-color: $color-main;
                }
            }
        }
    
    }
}

//======================================================================================================
// Actualités/Agenda
//======================================================================================================

.acag {
    margin: 100px 0;

    &__top {
        @include flex($alignItems: flex-end, $justifyContent: space-between);
        margin-bottom: 30px;
    }

    &__title {
        @extend %homeTitle;
        margin-left: 35px;
    }
 
    &__subtitle {
        @extend %homeSubtitle;
        margin-top: -5px;
    }

    &__navigation {
        display: flex; 
        gap: 15px;
        margin-bottom: 5px;

        &--prev, &--next {
            // Enlever style par défaut des flèches
            width: 40px!important;
            height: 40px!important;
            position: relative!important;
            top: unset!important;
            left: unset!important;
            right: unset!important;
            bottom: unset!important;
            margin-top: 0!important;
            background-color: $color-main;
            border: none;
            border-radius: $border-radius;
            transition: all $duration ease-in-out;
            cursor: pointer;
            z-index: 2!important;
            

            &:after {
                display: none;
            }

            svg {
                fill: $color-dark;
                height: 100%;
                width: 100%;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }

        }

        &.disable {
            display: none;
        }
    }

    &__swiper {
        padding: 10px!important;
        margin: -10px!important;
    }

    &__item {
        width: 370px!important;
        // margin-right: 30px!important;
        padding-bottom: 20px;

        &:hover, &:focus {
            .acag__image img {
                transform: scale(1.05);
            }

            .acag__date::before {
                background-color: $color-main;
            }

            .acag__content {
                box-shadow: 0px 0px 5px 0px #0017281A;
            }
        }

        &--post {
            .acag__type {
                padding-left: 32.5px;
                position: relative;

                &:before {
                    position: absolute;
                    content: "";
                    height: 25px;
                    width: 22.5px;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-image: url(/wp-content/themes/gauchy/assets/src/images/svg/bulle_acag_news.svg);
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &__type {
        @include flex($alignItems: center);
        font-family: $font-family;
        font-size: toRem(22);
        font-style: italic;
        font-weight: 400;
        line-height: toRem(25);
        gap: 10px;
        color: $color-dark;

        svg {
            width: 22.5px;
            height: 25px;
        }
    }

    &__image {
        overflow: hidden;
        margin: 0;
        width: 100%;
        height: 280px;
        margin-top: 15px;
        border-radius: $border-radius;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: scale(1);
            border-radius: $border-radius;
            transition: all $duration ease-in-out;
        }

        &--notimg {
            width: 100%;
            height: 280px;
            border-radius: $border-radius;

            &-event {
                background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-light center;
            }

            &-news {
                background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light center;
            }
        }
    }

    &__dates {
        @include flex($alignItems: center, $justifyContent: flex-end);
        gap: 10px;
        position: absolute;
        top: 300px;
        right: 20px;
        z-index: 1;
        
    }

    &__date {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        width: 60px;
        height: 60px;
        color: $color-dark;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            mask-repeat: no-repeat;
            background-color: $color-second;
            z-index: -1;
            transition: all $duration ease-in-out;
        }

        &:first-child:before {
            mask-image: url(/wp-content/themes/gauchy/assets/src/images/sprite-images/home/bulle_date_1.svg);
        }

        &:last-child:before {
            mask-image: url(/wp-content/themes/gauchy/assets/src/images/sprite-images/home/bulle_date_2.svg);
        }

        &--day {
            font-family: $font-family;
            font-size: toRem(18);
            font-weight: 800;
            line-height: toRem(20);
        }

        &--month {
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: 600;
            line-height: toRem(20);
            text-transform: uppercase;
        }
    }

    &__content {
        @include flex($direction: column);
        background-color: $color-white;
        box-shadow: $shadow;
        gap: 10px;
        margin-top: 15px;
        padding: 20px;
        box-sizing: border-box;
        height: 135px;
        border-radius: $border-radius;
        transition: all $duration ease-in-out;
    }

    &__category {
        font-family: $font-family--heading;
        font-size: toRem(20);
        font-weight: 300;
        line-height: toRem(25);
        text-transform: uppercase;
        color: $color-dark;
    }

    &__title--small {
        @extend %removeMargin;
        font-family: $font-family;
        font-size: toRem(25);
        font-weight: 800;
        line-height: toRem(30);
        color: $color-dark;
    }

    &__buttons {
        @include flex($justifyContent: flex-end);
        gap: 30px;
        margin-top: 20px;
    }

    &__button {
        display: flex;
        gap: 10px;
        margin: 0;
        width: 370px;

        svg {
            width: 20px;
            height: 20px;
            transition: all $duration ease-in-out;
        }

        &:first-child {
            background-color: $color-dark;
            border-color: $color-dark;
            color: $color-white;
            
            svg {
                fill: $color-white;
            }
        }
        
        &:hover, &:focus {
            background-color: $color-second;
            border-color: $color-second;
            color: $color-dark;

            svg {
                transform: rotate(90deg);
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    &__blocs {
        @include flex($justifyContent: space-between);
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 410px;
            height: 380px;
            left: -280px;
            top: 60px;
            border-radius: 438px;
            background: linear-gradient(221.59deg, rgba(255, 163, 43, 0.6) 27.12%, rgba(224, 110, 174, 0.6) 76.49%);
            filter: blur(100px);
        }
    }

    &__bloc {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        width: 170px;
        height: 170px;
        border-radius: $border-radius;
        box-shadow: $shadow;
        background: $color-white;
        gap: 10px;
        padding-top: 15px;
        position: relative;
        transition: all $duration ease-in-out;

        &:before {
            position: absolute;
            content: "";
            width: 36px;
            height: 40px;
            right: 35px;
            top: 35px;
            mask-image: url(/wp-content/themes/gauchy/assets/src/images/sprite-images/home/bulle_acces_rapides.svg);
            mask-repeat: no-repeat;
            background-color: $color-main;
            z-index: 1;
            transition: all $duration ease-in-out;
        }

        &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: linear-gradient(110deg, #6BD7D9 0%, #67D2B9 100%), linear-gradient(110deg, #6BD7D9 0%, #19A3AC 100%);
            transition: all $duration ease-in-out;
            z-index: 0;
            opacity: 0;
            border-radius: $border-radius;
        }

        svg {
            width: 75px;
            height: 75px;
            z-index: 1;
        }

        p {
            font-family: $font-family--heading;
            font-size: toRem(14);
            font-weight: 700;
            line-height: toRem(20);
            text-transform: uppercase;
            color: $color-dark;
            text-align: center;
            z-index: 1;
        }

        &:hover, &:focus {
            box-shadow: 0px 0px 5px 0px #0017281A;
            &:before {
                background-color: $color-white;
            }

            &:after {
                opacity: 1;
            }

        }
    }
}


//======================================================================================================
// Kiosque
//======================================================================================================

.kiosque {
    margin: 100px 0 80px;

    &__container {
        display: flex;
        position: relative;
    }

    &__header {
        margin: 25px 0 30px;
    }

    &__title {
        @extend %homeTitle;
        margin-left: -5px;
    }

    &__subtitle {
        @extend %homeSubtitle;
        margin-top: -5px;
    }

    &__left {
        width: 285px;
        box-sizing: border-box;
        margin-left: 45px;
    }

    &__date {
        font-family: $font-family--heading;
        font-size: toRem(16);
        font-weight: 300;
        line-height: toRem(25);
        text-transform: uppercase;
        color: $color-dark;
    }

    &__title--small {
        @extend %removeMargin;
        font-family: $font-family;
        font-size: toRem(22);
        font-weight: 700;
        line-height: toRem(25);
        color: $color-dark;
        margin: 10px 0 30px;
    }

    &__right {
        width: 690px;
        overflow: hidden;
        margin-left: 85px;
        margin-top: -20px;
    }

    &__buttons {
        display: flex;
        gap: 10px;
    }

    &__button {
        width: 40px;
        height: 40px;
        border-radius: $border-radius;
        background-color: transparent;
        border: 1px solid $color-main;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-dark;
            height: 100%;
            width: 100%;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-main;
        }
    }

    &__see-more {
        display: flex;
        gap: 10px;
        margin: 40px 0 0;
        padding: 0;
        width: 285px;
        height: 60px;
        background-color: $color-dark;
        border-color: $color-dark;
        color: $color-white;

        svg {
            width: 20px;
            height: 20px;
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-dark;
            
            svg {
                fill: $color-dark;
                transform: rotate(90deg);
            }
        }
    }

    .swiper-container {
        &__content {
            padding: 20px!important;
            margin: -20px!important;

            .swiper-slide {
                @include flex($direction: column, $justifyContent: center);
                background-color: $color-white;
                box-shadow: 0px 0px 20px 0px rgba(0, 23, 40, 0.10);
                opacity: 0!important;
                width: 285px;
                height: 265px;
                padding: 20px;
                box-sizing: border-box;
                border-radius: $border-radius;

                &-active {
                    opacity: 1!important;
                }
            }
        }

        &__images {
            width: 350px;
            height: 505px;
            overflow: visible;
            margin: 20px 0 20px 20px;

            .swiper-wrapper {
                display: flex;
                align-items: center;

                .swiper-slide {
                    position: relative;
                    z-index: 0;
                    height: 505px!important;
                    border-radius: 10px;
                    transition: all $duration ease-in-out;
                    box-shadow: (0px 0px 20px rgba(0, 23, 40, 0.15));

                    &:before {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
                        pointer-events: none;
                        transition: all $duration ease-in-out;
                    }

                    & img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                        transition: all $duration ease-in-out;
                    }

                    &-next {

                        &:before {
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
                        }
                    }

                    &-active {

                        &:before {
                            background: linear-gradient(0deg, rgba(3, 28, 65, 0), rgba(3, 28, 65, 0));
                        }
                    }

                    &-prev {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &__navigation {
        display: flex;
        gap: 720px;
        position: absolute;
        right: 15px;
        top: 233px;
    }

    &__prev, &__next {
        width: 40px;
        height: 40px;
        border-radius: $border-radius;
        background-color: $color-main;
        transition: all $duration ease-in-out;
        cursor: pointer;

        svg {
            fill: $color-dark;
            height: 100%;
            width: 100%;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

.social {
    &__top {
        @include flex($alignItems: flex-end, $justifyContent: space-between);
    }

    &__header {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 530px;
            height: 305px;
            left: -225px;
            top: -50px;
            background-image: url(/wp-content/themes/gauchy/assets/src/images/sprite-images/home/bulle_reseaux.svg);
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__title {
        @extend %homeTitle;
        margin-left: 45px;
    }

    &__subtitle {
        @extend %homeSubtitle;
        margin-left: 0;
        margin-top: -5px;
    }

    &__buttons {
        display: flex;
        gap: 10px;
        margin-bottom: 5px;
    }

    &__button {
        width: 40px;
        height: 40px;
        border-radius: $border-radius;
        background-color: $color-second;
        transition: all $duration ease-in-out;

        svg {
            fill: $color-dark;
            width: 100%;
            height: 100%;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }

    &__wall {
        margin-top: 30px;
    }

    #ff-stream-1 {
        padding: 0;
        min-height: 280px!important;
        max-height: 280px!important;
        background: transparent!important;
        box-shadow: 0 0 0 0 rgba(0,0,0,0)!important;

        .ff-header, .ff-nickname, .ff-timestamp, .ff-loadmore-wrapper {
            display: none;
        }

        .picture-item__inner {
            background: transparent!important;
            box-shadow: 0 0 0 0 rgba(0,0,0,0)!important;
        }

        .ff-item-cont {
            height: 280px!important;
        }

        .ff-stream-wrapper {
            display: flex;
            margin: 0;
            padding: 0;
            height: 100% !important;

            .ff-item {
                width: 570px!important;
                
                &:nth-child(1) {
                    transform: unset!important;
                }

                &:nth-child(2) {
                    transform: translateX(600px)!important;
                }

                &:hover, &:focus {
                    .ff-item-cont {
                        background-color: $color-white;
                        border-radius: 10px;
                    }

                    .ff-content {
                        box-shadow: 0px 0px 10px 0px #0017281A;
                    }

                    .ff-img-holder {
                        img {
                            min-width: 108%;
                            height: 108%;
                        }
                    }
                }
            }
        }

        .ff-img-holder {
            width: 350px!important;
            height: 250px!important;
            border-radius: $border-radius;
            overflow: hidden!important;

            img {
                height: 100%;
                object-fit: cover;
                border-radius: $border-radius;
                transition: all $duration ease-in-out;
            }
        }

        .ff-content {
            background-color: $color-white;
            width: 300px!important;
            height: 225px!important;
            border-radius: 10px;
            box-shadow: $shadow;
            padding: 20px 20px 105px;
            margin: -195px 0 0 270px;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);
            z-index: 1;
            position: relative;
            transition: all $duration ease-in-out!important;
        }

        .ff-item-bar {
            border: 0;
            width: 298px;
            margin-left: 272px;
            padding: 15px 20px;
            position: absolute;
            top: 223px;
            z-index: 2;
            background-color: $color-white;
            transition: all $duration ease-in-out!important;
        }

        .ff-item-meta {
            margin: 0 0 0 290px;
            width: 260px;
            position: absolute;
            top: 195px;
            height: 20px;
            z-index: 1;
            overflow: hidden;
            transition: all $duration ease-in-out!important;

            h6, a {
                font-family: $font-family;
                font-size: toRem(16)!important;
                font-weight: 700;
                line-height: toRem(24);
                height: 25px;
            }
        }
    }
}

//======================================================================================================
// Équipements
//======================================================================================================

.equipements {
    margin: 100px 0;

    &__container {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 410px;
            height: 380px;
            right: -250px;
            top: -30px;
            border-radius: 438px;
            background: linear-gradient(221.59deg, rgba(255, 163, 43, 0.6) 27.12%, rgba(224, 110, 174, 0.6) 76.49%);
            filter: blur(100px);
            z-index: -1;
        }
    }

    &__title {
        @extend %homeTitle;
        margin-left: 45px;
    }

    &__subtitle {
        @extend %homeSubtitle;
        margin-left: 0;
        margin-top: -5px;
    }

    &__blocs {
        @include flex($justifyContent: space-between);
        margin-top: 30px;
    }

    &__wrapper {
        @include flex($direction: column);
        gap: 15px;
        width: 370px;

        &--link {
            &:hover, &:focus {
                .equipements__image img {
                    transform: scale(1.05);
                }

                .equipements__title--small {
                    box-shadow: 0px 0px 5px 0px #0017281A;
                }
            }
        }
    }

    &__image {
        overflow: hidden;
        width: 100%;
        height: 250px;
        border-radius: $border-radius;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: scale(1);
            border-radius: $border-radius;
            transition: all $duration ease-in-out;
        }
    }

    &__title--small {
        @include flex($alignItems: center, $justifyContent: center);
        width: 100%;
        height: 60px;
        background-color: $color-white;
        color: $color-dark;
        border-radius: $border-radius;
        box-shadow: 0px 0px 20px 0px rgba(0, 23, 40, 0.10);
        font-family: $font-family--heading;
        font-size: toRem(20);
        font-weight: 400;
        line-height: toRem(25);
        text-transform: uppercase;
        text-align: center;
        transition: all $duration ease-in-out;
    }
}

//======================================================================================================
// Projets
//======================================================================================================

.projets {
    &__container {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 430px;
            height: 265px;
            right: 115px;
            top: -45px;
            background-image: url(/wp-content/themes/gauchy/assets/src/images/sprite-images/home/bulle_projets.svg);
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__header {
        position: absolute;
        right: 185px;
        top: 5px;
        z-index: 2;
    }

    &__title {
        @extend %homeTitle;
        margin-left: 40px;
    }

    &__subtitle {
        @extend %homeSubtitle;
        margin-top: -5px;
    }

    &__navigation {
        display: flex; 
        gap: 10px;
        margin-bottom: 5px;
        position: absolute;
        top: 0;
        left: 65px;

        &--prev, &--next {
            // Enlever style par défaut des flèches
            width: 40px!important;
            height: 40px!important;
            position: relative!important;
            top: unset!important;
            left: unset!important;
            right: unset!important;
            bottom: unset!important;
            margin-top: 0!important;
            background-color: $color-second;
            border: none;
            border-radius: $border-radius;
            transition: all $duration ease-in-out;
            cursor: pointer;
            z-index: 2!important;
            

            &:after {
                display: none;
            }

            svg {
                fill: $color-dark;
                height: 100%;
                width: 100%;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }

        }

        &.disable {
            display: none;
        }
    }

    &__blocs {
        overflow: visible!important;
    }

    .swiper-slide {
        opacity: 0!important;

        &-active {
            opacity: 1!important;
        }
    }

    &__link {
        display: flex;
        padding-top: 20px;

        &:hover, &:focus {
            .projets__image img {
                transform: scale(1.05);
            }

            .projets__content {
                box-shadow: 0px 0px 5px 0px rgba(0, 23, 40, 0.10);
            }
        }
    }

    &__image {
        overflow: hidden;
        width: 790px;
        min-width: 790px;
        height: 380px;
        border-radius: $border-radius;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: scale(1);
            border-radius: $border-radius;
            transition: all $duration ease-in-out;
        }
    }

    &__content {
        width: 430px;
        height: fit-content;
        padding: 30px;
        box-sizing: border-box;
        background-color: $color-white;
        border-radius: $border-radius;
        box-shadow: 0px 0px 20px 0px rgba(0, 23, 40, 0.10);
        margin-left: -50px;
        margin-top: 105px;
        position: relative;
        transition: all $duration ease-in-out;
    }

    &__title--small {
        font-family: $font-family;
        font-size: toRem(25);
        font-weight: 800;
        line-height: toRem(30);
        color: $color-dark;
    }

    &__description {
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);
        color: $color-dark;
        margin-top: 10px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home-slideshow {
        &:before {
            position: absolute;
            content: "";
            width: 355px;
            height: 330px;
            left: calc(50% + 350px);
            bottom: -215px;
        }

        .slideshow {
            width: 940px;
            height: 445px;
        }
    }

    .acag {
        margin: 80px 0;

        &__item {
            width: 300px!important;
            // margin-right: 20px!important;
        }

        &__type {
            font-size: toRem(20);
        }
    
        &__image {
            height: 230px;
    
            &--notimg {
                height: 230px;
            }
        }
    
        &__dates {
            top: 245px;
        }
    
        &__content {
            height: 125px;
        }
    
        &__category {
            font-size: toRem(18);
        }
    
        &__title--small {
            font-size: toRem(20);
            line-height: toRem(25);
        }
    
        &__buttons {
            gap: 20px;
        }
    
        &__button {
            width: 300px;
        }
    }

    .access {
        &__blocs {
            &:before {
                width: 355px;
                height: 330px;
                left: -280px;
                top: 60px; 
            }
        }
    
        &__bloc {
            width: 140px;
            height: 150px;
            gap: 15px;
            padding-top: 10px;
    
            &:before {
                width: 27px;
                height: 30px;
                right: 30px;
                top: 30px;
                mask-size: 27px;
            }
    
            svg {
                width: 60px;
                height: 55px;
            }
        }
    }

    .kiosque {
        margin: 80px 0 60px; 
    
        &__header {
            margin: 10px 0 30px 5px;
        }
    
        &__left {
            width: 260px;
            margin-left: 0;
        }
    
        &__title--small {
            font-size: toRem(18);
            margin: 10px 0 20px;
        }
    
        &__right {
            width: 510px;
            margin-left: 105px;
        }
    
        &__see-more {
            width: 260px;
        }
    
        .swiper-container {
            &__content {
                .swiper-slide {
                    width: 260px;
                    height: 210px; 
                }
            }
    
            &__images {
                width: 305px;
                height: 435px;
                margin: 20px 0;

                .swiper-wrapper {
                    .swiper-slide {
                        height: 435px!important;
                        box-shadow: 0 0 0 rgba(0,23,40,0);
                    }

                    .swiper-slide-prev {
                        opacity: 1;
                    }
                }
            }
        }
    
        &__navigation {
            gap: 555px;
            right: 10px;
        }
    }

    .social {
        &__header {
            &:before {
                width: 450px;
                height: 260px;
                left: -185px;
                top: -45px;
                background-size: 450px
            }
        }
    
        #ff-stream-1 {
            min-height: 250px!important;
            max-height: 250px!important;
            height: 250px!important;
    
            .ff-item-cont {
                height: 250px!important;
            }
    
            .ff-stream-wrapper {
                .ff-item {
                    width: 430px!important;
    
                    &:nth-child(2) {
                        transform: translateX(480px)!important;
                    }
                }
            }
    
            .ff-img-holder {
                width: 280px!important;
                height: 200px!important;
            }
    
            .ff-content {
                width: 255px!important;
                height: 205px!important;
                margin: -155px 0 0 205px;
                padding: 20px 20px 90px;
            }
    
            .ff-item-bar {
                width: 255px;
                margin-left: 205px;
                top: 195px;
            }
    
            .ff-item-meta {
                margin: 0 0 0 225px;
                width: 215px;
                top: 165px;
            }
        }
    }

    .equipements {
        margin: 80px 0;
    
        &__container {
            &:before {
                width: 355px;
                height: 330px;
                right: -210px;
                top: -50px;
            }
        }
    
        &__wrapper {
            width: 300px;
        }
    
        &__image {
            height: 200px;
        }
    
        &__title--small {
            font-size: toRem(18);
        }
    }

    .projets {
        &__container {
            &:before {
                width: 400px;
                height: 245px;
                right: 70px;
                top: -30px;
                background-size: 400px
            }
        }

        &__header {
            right: 125px;
            top: 15px;
        }
    
        &__image {
            width: 620px;
            min-width: 620px;
            height: 330px;
        }
    
        &__content {
            width: 390px;
            padding: 20px;
            margin-left: -70px;
            margin-top: 110px;
        }
    
        &__title--small {
            font-size: toRem(22);
            line-height: toRem(25);
        }
    
        &__description {
            font-size: toRem(14);
            line-height: toRem(20);
        }

        &__navigation {
            left: 60px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .home-slideshow {
        position: relative;

        &:before {
            right: -60px;
            left: unset;
        }

        .container--content {
            width: 90%;
            padding: 0 10px;
            margin: 0 auto;
        }

        .slideshow {
            width: 100%;
            height: 380px;

            img {
                border-radius: 0;
            }

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__content {
                max-width: 375px;
                right: 20px;
                bottom: 40px;
            }

            &__pagination {
                bottom: 15px;
                right: 0;
            }
        }
    }

    .acag {
        margin: 60px 0 80px;

        &__item {
            width: calc(50% - 10px)!important;
            padding-bottom: 15px;
        }

        &__image {
            height: 255px;
        }

        &__dates {
            top: 270px;
        }

        &__content {
            box-shadow: 0 0 12px 0 rgba(0,23,40,.1);
        }

        &__buttons {
            gap: unset;
            justify-content: space-between;
            margin-top: 0;
        }

        &__button {
            width: calc(50% - 10px)!important;
            padding: 0;
            height: 60px;
        }
    }

    .access {
        &__blocs {
            display: grid;
            grid-template-columns: repeat(3, 140px);
            gap: 20px;
            justify-content: center;

            &:before {
                width: 300px;
                height: 280px;
                left: -125px;
                top: 250px;
            }
        }
    }

    .kiosque {
        margin: 80px 0 80px;

        &__container {
            justify-content: space-between;
        }

        &__left {
            margin-left: 10px;
            order: 1;
            width: calc(50% - 70px);
            height: auto;
        }

        &__right {
            width: fit-content;
            margin-left: 0;
            margin-right: 10px;
            order: 3;
            width: calc(50% - 50px); 
            height: auto;
            margin-top: 0;
            border-radius: 10px;
            box-shadow: 0px 0px 20px 0px rgba(0, 23, 40, 0.10);
        }

        &__navigation {
            top: 185px;
            gap: 10px;
            right: unset;
            flex-direction: column;
            left: 50%;
            transform: translate(-50%);
            order: 2;

            .swiper-button-disabled {
                opacity: 0.35;
            }
        }

        &__see-more {
            width: 100%;
            min-width: 250px;
        }

        .swiper-container {
            &__images {
                width: 100%;
                height: 100%;
                margin: 0;

                .swiper-wrapper .swiper-slide {
                    width: 100%;
                    height: 100%!important;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .social {
        &__header {
            &:before {
                left: -155px;
                top: -40px;
            }
        }
    
        #ff-stream-1 {
            min-height: 350px!important;
            max-height: 350px!important;
            height: 350px!important;
    
            .ff-item-cont {
                height: 200px!important;
            }
    
            .ff-stream-wrapper {
                gap: 20px;
                height: fit-content!important;

                .ff-item {
                    width: calc(50% + 10px)!important;
                    height: fit-content!important;
                    position: relative!important;
                    top: unset!important;
                    left: unset!important;
                    right: unset!important;
                    bottom: unset!important;
    
                    &:nth-child(2) {
                        transform: none!important;
                        margin-right: -40px!important;
                    }
                }
            }
    
            .ff-img-holder {
                width: 100%!important;
            }
    
            .ff-content {
                width: calc(100% - 80px)!important;
                margin: 0;
                margin: -60px 0 0 40px;
            }
    
            .ff-item-bar {
                width: calc(100% - 80px);
                margin-left: 40px;
                top: 290px;
            }
    
            .ff-item-meta {
                margin: 0 0 0 60px;
                width: calc(100% - 100px);
                top: 260px;
                height: 50px;
            }
        }
    }

    .equipements {
        margin: 75px 0 80px;
    
        &__container {
            &:before {
                width: 300px;
                height: 280px;
                right: -90px;
            }
        }
    
        &__blocs {
           gap: 20px;
        }
    
        &__bloc {
            width: 33%;
        }

        &__wrapper {
            width: 100%;
        }
    
        &__image {
            height: 150px;
        }
    
        &__title--small {
            font-size: toRem(16);
            line-height: toRem(20);
        }
    }

    .projets {
        &__container {
            &:before {
                right: unset;
                top: -25px;
                left: calc(50% + -35px);
            }
        }

        &__header {
            left: 68%;
            right: unset;
            top: 30px;
        }
    
        &__image {
            width: 65%;
            min-width: unset;
            height: 330px;
        }
    
        &__content {
            width: 43%;
            margin-top: 0;
            margin-left: 0;
            position: absolute;
            top: 125px;
            right: 0;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home-slideshow {
        &:before {
            bottom: -160px;
            right: -160px;
        }
        .slideshow {
            height: fit-content;
            overflow: visible !important;

            &__container {
                display: flex;
                flex-direction: column;
            }

            img {
                height: 180px;
                position: relative;
            }

            &__content {
                position: relative;
                bottom: unset;
                margin-top: -10px;
                right: unset;
                width: calc(100% - 20px);
                max-width: unset;
                left: 50%;
                transform: translateX(-50%);
                padding: 15px;
            }

            &__title {
                font-size: toRem(16);
                line-height: toRem(16);
            }

            &__description {
                font-size: toRem(14);
                line-height: toRem(18);
            }

            &__pagination {
                bottom: unset;
                top: 150px;

                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }

    .acag {
        margin: 55px 0 80px;

        &__container {
            position: relative;
        }
        
        &__top {
            margin-bottom: 25px;
        }

        &__subtitle {
            margin-top: 0;
        }

        &__item {
            width: 100%!important;
        }

        &__image {
            height: 240px;
        }

        &__dates {
            top: 255px;
            right: 17px;
        }

        &__buttons {
            flex-direction: column;
            gap: 15px;
            margin-top: 70px;
        }

        &__button {
            width: 100%!important;
        }

        &__navigation {
            position: absolute;
            bottom: 160px;
            left: 50%;
            transform: translateX(-50%);
            gap: 10px;
        }

    }

    .access {
        &__blocs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &:before {
                left: -90px;
                top: unset;
                bottom: -190px;
            }
        }

        &__bloc {
            width: calc(50% - 10px);

            &:before {
                left: calc(50% + 15px);
            }
        }
    }

    .kiosque {
        margin: 80px 0 80px;

        &__container {
            flex-direction: column;
        }

        &__left {
            margin-left: 0;
            order: 3;
            width: 100%;
            margin-top: 20px;
        }

        &__header {
            position: absolute;
            top: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            margin-left: -10px;
        }

        &__title {
            margin-left: 0;
        }

        &__subtitle {
            margin-top: 0;
        }

        &__see-more {
            margin-top: 30px;
        }

        &__right {
            order: 1;
            width: calc(100% - 70px);
            margin: 105px auto 0;
        }

        &__navigation {
            position: relative;
            justify-content: center;
            order: 2;
            top: unset;
            flex-direction: row;
            margin-top: -27px;
            z-index: 1;
        }

        .swiper-container {
            &__content {
                .swiper-slide {
                    height: 175px;
                }
            }
        }
    }

    .social {
        &__top {
            flex-direction: column;
            align-items: flex-start;
            gap: 25px;
        }

        &__header {
            &:before {
                left: -85px;
                top: -95px;
                width: 330px;
                height: 195px;
                background-size: 330px;
            }
        }

        &__title {
            margin-left: 37px;
        }

        &__wall {
            margin-top: 20px;
        }
    
        #ff-stream-1 {
            min-height: 685px!important;
            max-height: 685px!important;
            height: 685px!important;
    
            .ff-item-cont {
                height: 180px!important;
            }
    
            .ff-stream-wrapper {
                gap: 185px;
                flex-direction: column;

                .ff-item {
                    width: calc(100%)!important;
                }
            }
    
            .ff-content {
                margin: -60px 0 0 25px;
                width: calc(100% - 50px) !important;
            }
    
            .ff-item-bar {
                margin-left: 25px;
                width: calc(100% - 50px);
            }
    
            .ff-item-meta {
                width: calc(100% - 90px);
                margin: 0 0 0 45px;
            }
        }
    }

    .equipements {
        margin: 105px 0 80px;
        position: relative;
        z-index: 1;
    
        &__container {
            &:before {
                width: 300px;
                height: 280px;
                right: -90px;
            }
        }

        &__title {
            margin-left: 35px;
        }
    
        &__blocs {
           flex-direction: column;
           margin-top: 35px;
        }
    
        &__bloc {
            width: 100%;
        }
    
        &__image {
            height: 200px;
        }
    }

    .projets {
        &__container {
            padding-top: 105px;

            &:before {
                left: calc(50% - 260px);
                top: -90px;
            }
        }

        &__header {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &__title {
            margin-left: 24px;
        }

        &__subtitle {
            margin-top: 0;
        }

        &__link {
            flex-direction: column;
        }
    
        &__image {
            width: 100%;
            height: 200px;
        }
    
        &__content {
            width: calc(100% - 35px);
            position: relative;
            top: unset;
            right: unset;
            transform: translateX(-50%);
            left: 50%;
            margin-top: -40px;
            padding: 15px;
        }

        &__navigation {
            transform: translateX(-50%);
            left: 50%;
            z-index: 2;
            top: 105px;
        }
    }
}
